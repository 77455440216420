<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.17/vue.js"></script> -->

<template>
  <tbody class="tableLines">
    <tr
      class="rowResults"
      v-if="item.status == 'Submetido' && this.execucoesSkynet != null"
    >
      <td class="text-center rowResult">
        {{ this.execucoesSkynet[0].DataInicio | toDataHoraPtbrMenos3h }}
      </td>
      <td class="text-center rowResult">
        <span v-if="item.produto == 'FastModel'">
          Logistic Reg.
        </span>
        <span v-if="item.produto == 'MachineLearning'">
          {{ atributosSeparados }}
        </span>
      </td>
      <td class="text-center rowResult">
        <span>
          {{ amostraSeparada }}
        </span>
      </td>
      <td class="text-center rowResult">
        <div class="selectIcon">
          <button
            :id="'btnIconHash' + index + indexInt"
            class="btnIcon"
            v-clipboard:copy="item.hash_execucao_skynet"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined btnIconInside"> tag </span>
          </button>
          <b-tooltip
            :target="'btnIconHash' + index + indexInt"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            {{ item.hash_execucao_skynet }}
          </b-tooltip>
        </div>
      </td>
      <td class="text-center rowResult" style="width: 21%">
        <b-progress :id="'btnIconProgress' + index + indexInt" height="1.5rem">
          <b-progress-bar
            :value="this.execucoesSkynet[0].PercentualEstimado * 100"
            class="bProgress"
            :class="{
              progressCanceled:
                this.execucoesSkynet[0].Status == 'Cancelado' ||
                this.execucoesSkynet[0].Status == 'EmCancelamento',
              progressEnd:
                this.execucoesSkynet[0].Status == 'FinalizadoComSucesso',
              bProgressError:
                this.execucoesSkynet[0].Status == 'FinalizadoComErro',
            }"
          >
            {{
              (this.execucoesSkynet[0].PercentualEstimado * 100).toFixed(2) +
              "%"
            }}
          </b-progress-bar>
        </b-progress>
      </td>
      <td class="text-center rowResult">
        {{ this.execucoesSkynet[0].StatusDetalhe }}
      </td>
      <td class="text-center rowResult">
        <div class="linkLote">
          <div class="iconLote selectIcon">
            <button
              :id="'btnIconDownload' + index + indexInt"
              class="btnStatus"
              v-if="
                this.execucoesSkynet[0].Status == 'FinalizadoComSucesso' ||
                this.execucoesSkynet[0].Status == 'FinalizadoComErro'
              "
            >
              <a :href="this.execucoesSkynet[0].LinkTemporarioParaDownload">
                <span
                  class="material-symbols-outlined iconAlign"
                  :class="{
                    colorBlue:
                      this.execucoesSkynet[0].Status == 'FinalizadoComSucesso',
                    colorRed:
                      this.execucoesSkynet[0].Status == 'FinalizadoComErro',
                  }"
                  >file_download</span
                >
              </a>
            </button>
            <b-tooltip
              v-if="this.execucoesSkynet[0].Status == 'FinalizadoComSucesso'"
              :target="'btnIconDownload' + index + indexInt"
              triggers="hover"
              placement="bottom"
              variant="secondary"
              class="tooltipResults"
              boundary="viewport"
            >
              Download Integração
            </b-tooltip>
            <b-tooltip
              v-if="this.execucoesSkynet[0].Status == 'FinalizadoComErro'"
              :target="'btnIconDownload' + index + indexInt"
              triggers="hover"
              placement="bottom"
              variant="secondary"
              class="tooltipResults"
              boundary="viewport"
            >
              Download Erros
            </b-tooltip>
          </div>
        </div>
      </td>
      <div>
        <span class="tooltip__content" :class="{ show: flag }">Copiado!</span>
      </div>
    </tr>
  </tbody>
</template>

<script >
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapWritableState, mapActions } from "pinia";
import { skynetService } from "@/services";

import Swal from "sweetalert2";

export default {
  name: "LinhasLotes",
  components: {},
  props: {
    index: Number,
    indexInt: Number,
    item: Object,
    usuario: Object,
  },

  data() {
    return {
      // refreshValues: [],
      execucoesSkynet: null,
      execucoesSkynetRefresh: null,
      flag: false,
      liberaSpinner: false,
      counterInt: 0,
      nomeAbordagemHistorico: "",
      colunasSeparadas: [],
      atributosSeparados: "",
      amostraSeparada: "",
    };
  },

  methods: {
    copyInfo() {
      this.flag = true;
      setTimeout(() => {
        this.flag = false;
      }, 1000);
    },

    async recuperaExecucaoSkynet() {
      this.$emit("showLoad");
      if (this.item.status == "Submetido") {
        let periodoDefault = 30;
        const objetoHistorico = {
          hashExecucao: this.item.hash_execucao_skynet,
          periodoEmDias: periodoDefault,
        };
        const query = new URLSearchParams(objetoHistorico);

        this.execucoesSkynet = await skynetService.recuperaExecucoesSkynet(
          query.toString()
        );
      }
      this.activeRefresh();
    },

    activeRefresh() {
      this.counterInt = setInterval(this.refreshRow, 5000);
      this.$emit("hideLoad");

    },
    refreshRow() {
      if (
        this.execucoesSkynet != null &&
        this.execucoesSkynet[0].Status != "FinalizadoComSucesso" &&
        this.execucoesSkynet[0].Status != "FinalizadoComErro" &&
        this.execucoesSkynet[0].Status != "Cancelado"
      ) {
        this.refreshValuesRows();
      } else {
        clearInterval(this.counterInt);
      }
    },

    async refreshValuesRows() {
      if (this.item.status == "Submetido") {
        let periodoDefault = 30;
        const objetoHistorico = {
          hashExecucao: this.item.hash_execucao_skynet,
          periodoEmDias: periodoDefault,
        };
        const query = new URLSearchParams(objetoHistorico);

        this.execucoesSkynetRefresh =
          await skynetService.recuperaExecucoesSkynet(query.toString());
      }
      this.execucoesSkynet[0].Status = this.execucoesSkynetRefresh[0].Status;
      this.execucoesSkynet[0].StatusDetalhe =
        this.execucoesSkynetRefresh[0].StatusDetalhe;
      this.execucoesSkynet[0].PercentualEstimado =
        this.execucoesSkynetRefresh[0].PercentualEstimado;
      this.execucoesSkynet[0].LinkTemporarioParaDownload =
        this.execucoesSkynetRefresh[0].LinkTemporarioParaDownload;

    },

    criaNomeAtributos() {
      this.colunasSeparadas = this.item.texto_parametros.split(";");
      this.colunasSeparadas.forEach((coluna) => {
        coluna = coluna.split("=");
        if (coluna[0] == "algoritmo") {
          this.atributosSeparados = coluna[1];
          this.atributosSeparados = this.atributosSeparados.replaceAll(
            ",",
            " & "
          );
        }
        if (coluna[0] == "variavelAmostra") {
          this.amostraSeparada = coluna[1];
        }
      });
    },
  },

  mounted() {
    this.recuperaExecucaoSkynet();
    this.criaNomeAtributos();
  },

  beforeDestroy() {
    clearInterval(this.counterInt);
  },

  created() {},

  computed: {
    ...mapWritableState(useSkynetMemoryStore, [
      "identificadorMemory",
      "projectName",
      "abordagemMemory",
      "resultadoIntegracaoMemory",
      "msgErroIntegracaoMemory",
      "counterMemory",
    ]),
  },
};
</script>
      
<style lang="scss" scoped>
.rowResult {
  width: 14%;
  // height: 70px;
  vertical-align: middle;
  line-height: 14px;
  padding: 10px;
}

.btnIcon {
  display: flex;
  align-items: center;
  height: 35px;
}

.tooltip__content {
  background: #616161;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  position: fixed;
  left: 18rem;
  bottom: 3%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  z-index: 99;
  @include mediaQueryMax(medium) {
    left: 0.9rem;
  }
}

.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.bProgress {
  background-color: #28a745;
}

.progressCanceled {
  background-color: #b8b8b8;
}

.progressEnd {
  background-color: #1da0f2;
}

.bProgressError {
  background-color: #b71c1c !important;
}
.colorBlue {
  color: #1da0f2;
}

.colorRed {
  color: #b71c1c !important;
}

.selectIcon {
  display: inline-block;
  text-align: center;
}

.btnIcon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-inline: 3px;
}

.btnIcon:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20%;
}

.btnIcon:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}

.btnStatus {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0%;
  margin: 0 3px;
  width: 30px;
  height: 30px;
  /* display: block; */
  text-align: center !important;
  align-content: center !important;
  align-items: center !important;
  align-self: center !important;
}

.btnStatus:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 25%;
}

.btnStatus:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}

.linkLote {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.iconLote {
  display: inline-block;
  text-align: center;
}

.iconAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>